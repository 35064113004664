import { isAnyPartOfElementInViewport } from "./utils";

const { device } = require("device.js");

const $mapBox = $("#map");

export default function() {
  const mapContainer = document.getElementById("map");

  if (!mapContainer || !google) {
    return;
  }

  let map;
  const coordsCenter = new google.maps.LatLng(47.22743305280027, 39.706173469499625);
  const zoomIndex = 16;
  const styles = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  const mapProp = {
    center: coordsCenter,
    zoom: zoomIndex,
    styles: styles,
    zoomControl: false,
    fullscreenControl: false,
  };

  function initMap() {
    map = new google.maps.Map(document.getElementById("map"), mapProp);

    const image = "/i/pin.svg";
    const marker = new google.maps.Marker({
      position: coordsCenter,
      map: map,
      icon: image,
    });
  }

  if (!device.mobile) {
    initMap();
    return;
  }

  function loadMobileMap() {
    if (isAnyPartOfElementInViewport($($mapBox)[0]) && $mapBox.attr("data-loaded") === "false") {
      $mapBox.attr("data-loaded", "true");
      initMap();
    }
  }

  document.addEventListener("scroll", loadMobileMap, {
    passive: true,
  });
  document.addEventListener("resize", loadMobileMap, {
    passive: true,
  });
}
