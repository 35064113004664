import Slider from "./slider";
import { getFullUrl, isAnyPartOfElementInViewport } from "./utils";

const url = "/reviews-api";

function preparePicture(pictureUrl, { fullname, position, project }) {
  const url = process.env.RUNS_IN_VARIOUS_NETWORKS === "true" ? getFullUrl(process.env.API_HOST, pictureUrl) : pictureUrl;

  return ` 
        <picture>
          <source type="image/jpg" data-srcset="${url}">
          <img class="reviews-main__avatar lazyload" data-src="${url}" alt="${fullname} - ${position} ${project}">
        </picture>`;
}

function renderReviewCards(data) {
  const tempList = document.createDocumentFragment();

  data.forEach(item => {
    const { photo, fullname, text, position, project, socialLink } = item;

    const picture = photo?.url ? preparePicture(photo.url, { fullname, position: position.text, project: project.text }) : "";

    const reviewListElement = `
        <div class="reviews-card-container hidden">
          <div class="reviews-main__card reviews-card js-reviewsCarousel-card">
            <div class="reviews-main__top">
                ${
                  picture && socialLink
                    ? `<a class="reviews-main__social-link" target="_blank" rel='nofollow' href="${socialLink}">${picture}</a>`
                    : picture
                }
              <div class="reviews-main__reviewer-info">
                ${
                  socialLink
                    ? `<a class="reviews-main__fullname" target="_blank" rel='nofollow' href="${socialLink}">${fullname}</a>`
                    : `<p class="reviews-main__fullname">${fullname}</p>`
                }
                <div class="wrapp-specialty">
                ${
                  position.link
                    ? `<a class="reviews-main__specialty reviews-main__specialty-link" target="_blank" rel='nofollow' href="${position.link}">${position.text}</a>`
                    : `<p class="reviews-main__specialty">${position.text}</p>`
                }
                  , 
                ${
                  project.link
                    ? `<a class="reviews-main__specialty reviews-main__specialty-link" target="_blank" rel='nofollow' href="${project.link}">${project.text}</a>`
                    : `<p class="reviews-main__specialty">${project.text}</p>`
                }  
                </div>
              </div>
            </div>
            <div class="reviews-main__content">
              <p class="reviews-main__text">${text}</p>
            </div>
            <img class="reviews-main__quotes lazyload" data-src="/i/quotes.svg">
          </div>
        </div>`;
    $(reviewListElement).appendTo(tempList);
  });

  return tempList;
}

function loadReviews() {
  const reviewsLine = $("#reviewsCarouselLine");

  if (isAnyPartOfElementInViewport($(reviewsLine)[0], 500) && reviewsLine.attr("data-loaded") === "false") {
    reviewsLine.attr("data-loaded", "true");

    const test = {};

    $.ajax({
      url,
      type: "GET",
      test,
      contentType: false,
      cache: false,
      success: function(data) {
        const sortedReviews = sortReviewsBySortingField(data);
        reviewsLine.append(renderReviewCards(sortedReviews));
        reviewsLine.removeClass("hidden");
        initReviewsSlider();
      },
      error: function(e) {
        console.log(e);
      },
    });
  }
}

function sortReviewsBySortingField(reviews) {
  return [...reviews].sort((a, b) => a.sorting - b.sorting);
}

function initReviewsSlider() {
  const elements = {
    wrapper: $("#reviewsCarouselWrapper"),
    slider: $("#reviewsCarouselLine"),
    cards: $(".reviews-card-container"),
  };

  const setWidth = () => {
    const { slider, cards } = elements;

    const screenWidth = $(window).width();
    const blockWidth = $(".section__in").width();
    const padding = screenWidth < 1152 ? 16 : 24;

    const cardWidth = screenWidth < 768 ? blockWidth * 0.95 : Math.max((blockWidth - padding) / 2, 480);

    cards.each(function() {
      $(this).width(cardWidth);
    });

    slider.width((cards.outerWidth() + padding) * cards.length);
  };

  new Slider({
    elements,
    onInit: setWidth,
    onResize: setWidth,
  }).init();
}

export default function() {
  document.addEventListener("scroll", loadReviews, {
    passive: true,
  });
  document.addEventListener("resize", loadReviews, {
    passive: true,
  });
}
